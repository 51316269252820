<template>
  <div class="user-report-list fixed-draggable-dynamic-table-wrapper-height">
    <draggable-dynamic-table v-if="columnsLabel.length > 2"
                             :options="options"
                             :columns="columnsLabel"
                             :data="data"
                             :zero-top-radius="true"
                             @row:clicked="handleRowClicked"/>

    <!-- edit user prompt -->
    <vs-prompt
      class="medium-prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="editSaleAddonPromptStatus"
      @close="editSaleAddonPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('sendDiscountDataBTN')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            ویرایش تخفیفات
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="editSaleAddonPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <button id="editSaleDiscount" v-show="false" @click="editSaleAddonPromptStatus = true"/>
  </div>
</template>

<script>
  import axios from 'axios'
  import CustomIcon from "../../../../components/customIcon/customIcon";
  import {checkUserPermissions, hideLoading, showLoading} from "../../../../assets/js/functions";
  import {getTerms} from "../../../../http/requests/terms";
  // import {getUserReport} from "../../../../http/requests/discountGroups";
  export default {
    name: "userReport",
    components: {CustomIcon},
    metaInfo: {
      title: 'گزارشات شخص'
    },
    data () {
      return {
        showSaleAddonPromptStatus: false,
        insertNewSaleAddonPromptStatus: false,
        editSaleAddonPromptStatus: false,
        selectedRow: {},
        filters: [],
        sorts: ['order[0]=created_at,desc'],
        data: [],
        page: 1,
        total_count: null,
        loadingTimer: 0,
        options: {
          rowKeyField: 'id'
        },
        columnsLabel: [
          {
            field: 'name',
            headerName: 'نام شخص',
            align: 'center',
            width: 'calc((100% / 10) * 2)',
            minWidth: 200,
            sortable: true,
            filter: true,
            filterTypes: [
              {
                icon: 'search',
                name: 'شامل شود',
                i18n: 'draggableTable.filter.types.search',
                id: 1
              }
            ]
          },
          {
            field: 'rowNumber',
            headerName: 'ردیف',
            align: 'center',
            width: '60px',
            minWidth: 60,
            // sortable: true,
            // filter: true,
            // filterTypes: [
            //   {
            //     icon: 'search',
            //     name: 'شامل شود',
            //     i18n: 'draggableTable.filter.types.search',
            //     id: 1
            //   }
            // ]
          },
        ],
        actions: [
          {
            toolbar: [
              {
                id: 'editSaleDiscount',
                icon: 'EDIT',
                iconPack: 'useral',
                color: 'warning',
                permission: 'discount_group.update'
              }
            ],
            leftToolbar: [
              {
                id: 'printTable',
                // i18n: 'draggableDynamicTable.actions.print',
                icon: 'icon-printer',
                iconPack: 'feather'
              },
              {
                id: 'downloadTable',
                // i18n: 'draggableDynamicTable.actions.download',
                icon: 'icon-download',
                iconPack: 'feather'
              },
              // {
              //   id: {name: 'saleAddonsTrash'},
              //   type: 'link',
              //   icon: 'icon-trash',
              //   iconPack: 'feather',
              //   permission: 'sale_addon.delete'
              // },
              {
                id: 'settingTable',
                icon: 'icon-settings',
                iconPack: 'feather'
              }
            ]
          }
        ]
      }
    },
    created () {
      setTimeout(() => {
        this.$store.dispatch('auth/setAccessToken')
        // this.$store.dispatch('updateNavbarActions', this.actions[0])
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      }, 50)

      // if (checkUserPermissions('character.show')) {
      //   this.getCharacters()
      // }
      // this.getUserReport()
      this.getTerms()
    },
    mounted () {
      setTimeout(() => {
        showLoading()
      }, 480)
    },
    methods: {
      checkUserPermissions(permission) {
        return checkUserPermissions(permission)
      },
      // getCharacters () {
      //   getCharacters().then(response => {
      //     const characters = response.data.data
      //     const character_index = this.columnsLabel.map(e => e.field).indexOf('character')
      //     if (character_index > -1) {
      //       characters.forEach(character => {
      //         this.columnsLabel[character_index].filterTypes.push({
      //           value: character.id,
      //           label: character.name
      //         })
      //       })
      //     }
      //   })
      // },
      getUserReport () {
        clearTimeout(this.loadingTimer)
        this.loadingTimer = setTimeout(() => {
          if (this.data.length < this.total_count || !this.total_count) {
            if (this.$refs.userReportList && this.data.length === 0) this.$refs.userReportList.loadMoreStatus = 'FirstLoad'
            else if (this.$refs.userReportList && this.data.length > 0) this.$refs.userReportList.loadMoreStatus = 'Loading'

            getUserReport(this.page, this.filters, this.sorts).then((response) => {
              const discounts = response.data
              let data = []
              discounts.data.forEach((saleAddon) => {

                if (!data[`character_${saleAddon.character.id}`]) {
                  data[`character_${saleAddon.character.id}`] = []
                }
                data[`character_${saleAddon.character.id}`].id = saleAddon.character.id
                data[`character_${saleAddon.character.id}`].name = saleAddon.character.name
                if (saleAddon.percent > 0) {
                  data[`character_${saleAddon.character.id}`][`term_${saleAddon.term.id}`] = {
                    value: saleAddon.percent,
                    type: 'price',
                    currency: '%'
                  }
                } else {
                  data[`character_${saleAddon.character.id}`][`term_${saleAddon.term.id}`] = {
                    value: saleAddon.price,
                    type: 'price',
                    currency: 'تومان'
                  }
                }
              })
              this.data = Object.values(data)

              this.total_count = saleAddons.pagination.total
              this.page = saleAddons.pagination.current_page + 1

              if (response.data.pagination.current_page === 1) {
                const row_index = this.columnsLabel.map((e) => {
                  return e.field
                }).indexOf('rowNumber')
                this.columnsLabel[row_index].footer.value = response.data.pagination.total
              }

              if (this.$refs.userReportList) this.$refs.userReportList.loadMoreStatus = ''
            }).catch((error) => {
              if (axios.isCancel(error)) {
                this.$vs.notify({
                  title: 'هشدار',
                  text: 'درخواست قبلی شما در حال پردازش است، لطفا منتظر بمانید',
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400,
                  color: 'warning'
                })
              } else if (this.$refs.userReportList) this.$refs.userReportList.loadMoreStatus = 'Danger'
            })
          }
        }, 400)
      },
      getTerms () {
        getTerms().then(response => {
          const terms = response.data.data
          let columnsLabel = JSON.parse(JSON.stringify(this.columnsLabel))
          terms.forEach(term => {
            columnsLabel.unshift(
              {
                field: `term_${term.id}`,
                headerName: term.name,
                width: 'calc(100% / 10)',
                minWidth: 150,
                sortable: true,
                filter: true,
                filterTypes: [
                    {
                        icon: 'search',
                        name: 'شامل شود',
                        i18n: 'draggableTable.filter.types.search',
                        id: 1
                    }
                ]
              })
          })
          columnsLabel.unshift(
            {
              field: 'total',
              headerName: 'جمع کل',
              width: 'calc(100% / 10)',
              minWidth: 150,
              sortable: true,
              filter: true,
              filterTypes: [
                {
                  icon: 'search',
                  name: 'شامل شود',
                  i18n: 'draggableTable.filter.types.search',
                  id: 1
                }
              ]
            })
          this.columnsLabel = []
          this.columnsLabel = columnsLabel
          hideLoading()
        })
          .catch(error => {
            hideLoading()
          })
      },
      handleRowClicked (row) {
        this.selectedRow = row
        this.showSaleAddonPromptStatus = true
      },
      handleReloadList () {
        this.data = []
        this.page = 1
        this.total_count = 0
        this.showSaleAddonPromptStatus = false
        this.insertNewSaleAddonPromptStatus = false
        this.editSaleAddonPromptStatus = false
        this.getUserReport()
      },
      handleClick (id) {
        document.getElementById(id).click()
      }
    }
  }
</script>

<style scoped>

</style>
